import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyC7d76w1yjzLZ1v6BpfDHXwwsaSpvnxyB4",
  authDomain: "nadine-51fc9.firebaseapp.com",
  projectId: "nadine-51fc9",
  storageBucket: "nadine-51fc9.appspot.com",
  messagingSenderId: "207222326355",
  appId: "1:207222326355:web:5659566fa7aa95f8b132a6"
};


firebase.initializeApp(firebaseConfig)


const firestore = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const timestamp = firebase.firestore.Timestamp

export { firestore, auth, timestamp, storage }